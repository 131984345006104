import React from 'react';
import { RawIntlProvider, createIntlCache, createIntl } from 'react-intl';

import messages_de from './de.json';
import messages_en from './en.json';

const locale =
    process.env.GATSBY_COUNTRY_BUILD === 'uk'
        ? 'en'
        : process.env.GATSBY_COUNTRY_BUILD;

const cache = createIntlCache();

const messages = {
    de: messages_de,
    en: messages_en
};

export const rawIntl = createIntl(
    {
        locale,
        messages: messages[locale]
    },
    cache
);

export const IwIntlProvider = ({ children }) => {
    return <RawIntlProvider value={rawIntl}>{children}</RawIntlProvider>;
};
