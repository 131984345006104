import React, { useState } from 'react';
import { IwProvider } from './IwContext';
import { MODALS } from '../util/iwocaConstants';
import { Amplitude, AmplitudeSignup } from '@iwoca/frontend-tracking-library';
import { getCurrentLocale } from '../util/localization';
import { AMPLITUDE_EVENT_NAMES } from '../util/tracking/amplitudeEvents';

const IwContextProvider = ({ children, defaultValue }) => {
    const [activeModals, setActiveModals] = useState([]);
    const [prefilledOptions, setPrefilledOptions] = useState({});

    function handleOpenModal(modalId) {
        const newListOfActiveModals = [...activeModals, modalId];
        setActiveModals(newListOfActiveModals);
    }

    function handleCloseModal(modalId) {
        // window.location.hash = '';

        if (modalId) {
            const newModalArray = activeModals.filter((modal) => {
                return modal !== modalId;
            });
            setActiveModals(newModalArray);
        } else {
            setActiveModals([]);
        }

        Amplitude.track(
            AmplitudeSignup.signupEventCreator({
                event: AMPLITUDE_EVENT_NAMES.NEW_APPLICATION_MODAL_CLOSED,
                locale: getCurrentLocale(),
                payload: {
                    url: window?.location?.href
                }
            })
        );

        setBorrowAmount(null);
        setCovidFilterArticle(null);
    }

    function handleOpenStepZero() {
        handleOpenModal(MODALS.signupStepZero);
        Amplitude.track(
            AmplitudeSignup.signupEventCreator({
                event: AMPLITUDE_EVENT_NAMES.NEW_APPLICATION_MODAL_VIEWED,
                locale: getCurrentLocale(),
                payload: {
                    url: window?.location?.href
                }
            })
        );
    }

    const [borrowAmount, setBorrowAmount] = useState(null);

    function handleBorrowAmount(amount) {
        setBorrowAmount(amount);
    }

    function handleSetPrefilledOptions(options) {
        setPrefilledOptions(options);
    }

    const [covidFilterArticle, setCovidFilterArticle] = useState(null);

    function handleOpenCovidFilterMobileModal(article) {
        setCovidFilterArticle(article);
        handleOpenModal('covidFilterArticleMobile');
    }

    const [apiErrorMessage, setApiErrorMessage] = useState(null);

    function handleApiErrorMessage(message) {
        setApiErrorMessage(message);
        setTimeout(() => {
            setApiErrorMessage(null);
        }, 4000);
    }

    const value = {
        prefilledOptions,
        setPrefilledOptions: handleSetPrefilledOptions,
        activeModals: activeModals,
        openModal: handleOpenModal,
        openStepZero: handleOpenStepZero,
        closeModal: handleCloseModal,
        borrowAmount,
        setBorrowAmount: handleBorrowAmount,
        openCovidFilterMobileModal: handleOpenCovidFilterMobileModal,
        apiErrorMessage,
        showApiError: handleApiErrorMessage,
        covidFilterArticle
    };

    return <IwProvider value={defaultValue || value}>{children}</IwProvider>;
};

export default IwContextProvider;
