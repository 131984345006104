import { useContext } from 'react';
import sha1 from 'crypto-js/sha1';
import hexEncode from 'crypto-js/enc-hex';
import queryString from 'query-string';
import lodashGet from 'lodash/get';
import Cookies from 'js-cookie';
import * as variables from '../variables.json';
import { getCountryBuild } from '../util/localization';
import { getFullIwocaUrl } from 'aphrodite-shared/util/helpers';
import { lendingAPIPostSplitTest } from '../api/lendingApi';
import SplitTestContext from './IwSplitTestContext';

export function generateSplitTestData(configFile) {
    let splitTests = {};

    const configTests = lodashGet(configFile, 'tests');
    if (!configTests) return splitTests;

    for (let index = 0; index < configTests.length; index++) {
        const branch = getSplitTestBranch(configTests[index]);
        splitTests[configTests[index].name] = branch;
    }

    return splitTests;
}

export function getSplitTestBranch(singleSplitTest) {
    const testName = singleSplitTest.name;
    const splitTestCookie = Cookies.get(`iw-${testName}`);
    let splitTestCountry = getCountryBuild();

    if (!singleSplitTest.countries[splitTestCountry]) {
        splitTestCountry = 'default';
    }

    if (splitTestCookie) {
        return JSON.parse(splitTestCookie);
    }

    // TODO: check for eligibility requirements

    const UIDCookie = getUID();
    const randomNumber = splitTestRandomNumber(testName, UIDCookie);

    const activeBranch = getRandomBranch(
        singleSplitTest.countries[splitTestCountry],
        randomNumber
    );

    const currentDateTime = new Date();

    const splitTestData = {
        test_name: testName,
        branch_name: activeBranch.name,
        created: currentDateTime.toISOString(),
        fraction: activeBranch.percentage,
        random_value: randomNumber
    };

    updateSplitTestData(splitTestData);

    return splitTestData;
}

function updateSplitTestData(data) {
    const splitTestCookie = Cookies.get(`iw-${data['test_name']}`);

    if (!splitTestCookie) {
        if (process.env.NODE_ENV === 'production') {
            const siteURL = getFullIwocaUrl('');
            setProductionSplitTestCookies(data, siteURL);
        } else {
            Cookies.set(`iw-${data['test_name']}`, JSON.stringify(data));
        }

        typeof window !== 'undefined' &&
            window.fetch &&
            lendingAPIPostSplitTest(data);

        postSplitTestDataToGtmDataLayer(data);
    }
}

function setProductionSplitTestCookies(data, siteURL) {
    Cookies.set(`iw-${data['test_name']}`, JSON.stringify(data), {
        domain: siteURL,
        expires: 7665
    });
    variables.subdomains.forEach((subdomain) => {
        Cookies.set(`iw-${data['test_name']}`, JSON.stringify(data), {
            domain: `${subdomain}.${siteURL}`,
            expires: 7665
        });
    });
}

function getUID() {
    return Cookies.get('uid') || 'uid';
}

function postSplitTestDataToGtmDataLayer(data) {
    const dataLayerSplitTestEventData = wrapSplitTestDataForGtmDataLayer(data);
    if (!data) {
        console.warn('No split-test data found');
        return;
    }

    if (typeof window !== 'undefined') {
        if (!window.dataLayer) {
            console.warn(
                'Cant find GTM dataLayer. Posting it here now:',
                dataLayerSplitTestEventData
            );
            return;
        }
        window.dataLayer.push(dataLayerSplitTestEventData);
    }
}

function wrapSplitTestDataForGtmDataLayer(data) {
    const dataLayerSplitTestEventData = {
        event: 'addedUserToSplitTest',
        splitTestData: {
            ...data,
            uid: getUID()
        }
    };
    return dataLayerSplitTestEventData;
}

export function splitTestRandomNumber(testName, uid) {
    if (typeof window !== 'undefined' && window.DEFAULT_SPLIT_TESTS === true) {
        return 0.5;
    } else {
        const hashDigest = sha1(uid + testName);
        const hexString = hashDigest.toString(hexEncode, 16);
        const hexSubstring = hexString.substring(0, 8);
        const value = parseInt(hexSubstring, 16);
        return value / 4294967296;
    }
}

function getRandomBranch(splitTest, randomNumber) {
    let upperBound = 0.0;
    const testConfiguration = splitTest;

    for (let index = 0; index < testConfiguration.length; index++) {
        upperBound += testConfiguration[index]['test_percentage'];

        if (upperBound >= randomNumber) {
            return {
                name: testConfiguration[index]['test_value'],
                percentage: testConfiguration[index]['test_percentage']
            };
        }
    }
}

export function branchInSplitTestQuery(branch) {
    const queryParams = queryString.parse(
        typeof window !== 'undefined' && window.location.search
    );

    const { splitTestBranch } = queryParams;

    switch (typeof splitTestBranch) {
        case 'string':
            return branch === splitTestBranch;
        case 'object':
            if (Array.isArray(splitTestBranch)) {
                return splitTestBranch.includes(branch);
            }
            return false;
        default:
            return false;
    }
}

export function getActiveBranchFromQueryString() {
    const queryParams = queryString.parse(
        typeof window !== 'undefined' && window.location.search
    );

    const { splitTestBranch } = queryParams;

    switch (typeof splitTestBranch) {
        case 'string':
            return splitTestBranch;
        default:
            return false;
    }
}

export function cleanUpOldCookies(splitTestConfig) {
    const splitTestNames = getActiveSplitTestsCookieNames(splitTestConfig);

    const allCookies = Cookies.get();

    const setCookieNames = Object.keys(allCookies);

    setCookieNames.forEach((cookie) => {
        if (cookie.startsWith('iw-') && !splitTestNames.includes(cookie)) {
            Cookies.remove(cookie);
        }
    });

    return splitTestNames;
}

function getActiveSplitTestsCookieNames(splitTestConfig) {
    let spltTestNames = [];
    splitTestConfig['tests'].forEach((test) => {
        spltTestNames.push(`iw-${test.name}`);
    });

    return spltTestNames;
}

export function useGetSplitTestBranch(testName) {
    const context = useContext(SplitTestContext);

    const splitTestInQueryString = getActiveBranchFromQueryString();
    if (splitTestInQueryString) return splitTestInQueryString;

    const activeBranch = context[testName]['branch_name'];
    return activeBranch;
}
