export const ACCOUNT_PAGE_URL = {
    uk: '/account',
    de: '/konto'
};

export const SIGNUP_PAGE_URL = {
    uk: '/account/signup',
    de: '/konto/anmeldung'
};

export const MODALS = {
    signupStepZero: 'signupStepZero',
    contactHelp: 'contactHelp',
    covidFilterArticleMobile: 'covidFilterArticleMobile',
    cbils: 'cbils',
    compare: 'compare',
    copyModal: 'copyModal'
};

export const MAX_LOAN_SIZE = {
    de: 500000,
    en: 500000
};

export const LOCALE_CURRENCY = {
    de: 'EUR',
    en: 'GBP'
};

export const DEFAULT_PHONE_NUMBER = {
    en: {
        display: '020 3778 0274',
        link: 'tel:+442037780274'
    }
};
