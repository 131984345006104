import { ensureSessionIdAccessCookie } from './authHelpers';
import { buildLendingAPI } from '../api/lendingApiHelper';
import Cookies from 'js-cookie';

export async function addPageTag(pageTags) {
    if (typeof window === 'undefined') return;

    const pageTag = getPageTag(pageTags);

    if (!pageTag) return;

    // Log to console if in development mode
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
        console.log('Tagging page:', pageTag);
        return;
    }

    sendPageTagToApi(pageTag);
}

export async function sendPageTagToApi(pageTag) {
    const sessionId = ensureSessionIdAccessCookie();

    return await buildLendingAPI(
        '/api/referers/add_page_tag/',
        {
            method: 'POST',
            body: JSON.stringify({
                tag: pageTag
            })
        },
        { 'x-csrftoken': Cookies.get('csrftoken'), session_id: sessionId }
    );
}

export function getPageTag(pageTags) {
    const queryParameters = new URLSearchParams(window.location.search);
    const pageTagParam = queryParameters.get('ptag');
    let paramPageTag = null;
    if (pageTagParam) {
        paramPageTag = pageTags['param'][pageTagParam];
    }

    const path = `${window.location.pathname}${window.location.search}`;
    const pathWithoutSearch = normalisePath(window.location.pathname);
    const normalisedPath = normalisePath(path);
    const pagePageTag = pageTags['url'][normalisedPath];
    const pageWithoutSearchPageTag = pageTags['url'][pathWithoutSearch];

    if (paramPageTag) return paramPageTag;
    if (pagePageTag) return pagePageTag;
    if (pageWithoutSearchPageTag) return pageWithoutSearchPageTag;
}

export function normalisePath(path) {
    if (path === '/') return path;
    if (/.+\?/.test(path)) return path;

    return `${path.replace(/\/$/, '')}/`;
}
