import variables from '../variables.json';
import { ensureSessionIdAccessCookie } from '../util/authHelpers';

export function buildLendingAPI(url, overidingOptions, overidingHeaders) {
    ensureSessionIdAccessCookie();

    return new Promise((resolve, reject) => {
        const fetchConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                authorization: variables.lendingAPIAuthorisationToken,
                ...overidingHeaders
            },
            ...overidingOptions
        };
        fetch(url, {
            ...fetchConfig
        })
            .then((response) => {
                if (!response.ok) {
                    throw response;
                }
                return response;
            })
            .then((response) => response.text())
            .then((response) => {
                // Especially /application endpoints only return statuscodes,
                // so parsing the body as json will otherwise fail
                try {
                    return JSON.parse(response);
                } catch (e) {
                    return {};
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                error.text().then((errorMessage) => {
                    reject(errorMessage);
                });
            });
    });
}
