import Cookies from 'js-cookie';
import uuid from 'uuid';
import * as variables from '../variables.json';

export const ensureSessionIdAccessCookie = () => {
    let sessionId = Cookies.get('sessionid_access');
    if (!sessionId) {
        sessionId = uuid();
        Cookies.set('sessionid_access', sessionId, {
            expires: 60,
            domain: variables.cookieDomains[process.env.GATSBY_COUNTRY_BUILD]
        });
    }
    return sessionId;
};

export const fetchIsLoggedIn = () => {
    const authState =
        Cookies.get('isLoggedIn') === true ||
        Cookies.get('isLoggedIn') === 'true' ||
        Cookies.get('isLoggedIn') === 'True';
    return authState;
};

export const logSessionId = () => {
    let sessionId = ensureSessionIdAccessCookie();

    fetch('/api/access_log/', {
        method: 'POST',
        headers: { session_id: sessionId },
        body: JSON.stringify({
            url: window.location.pathname,
            params: window.location.search,
            referrer: document.referrer
        })
    })
        .then()
        .catch((error) => {
            typeof window !== 'undefined' &&
                window.Raven.captureException(error);
            console.warn('iwoca session id is not implemented yet');
        });
};
