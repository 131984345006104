import React from 'react';
import urljoin from 'url-join';
import * as variables from '../variables.json';
import NumberFormat from 'react-number-format';
import { getCountryBuild } from './localization';

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function iwocaBaseUrl(url) {
    const isUrl = url || '/';
    const baseUrl =
        process.env.NODE_ENV === 'production' ? '/' : 'https://iwoca.co.uk/';
    return urljoin(baseUrl, isUrl);
}

export function getFullIwocaUrl(url) {
    const baseUrl = variables.domains[process.env.GATSBY_COUNTRY_BUILD];
    return urljoin(baseUrl, url);
}

export const isEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Returns the Google Tag Manager ID for a specific countrybuild
 * Defaults to the UK ID
 *
 * @export
 * @returns GoogleTagManager ID
 */
export function getTagManagerID() {
    switch (process.env.GATSBY_COUNTRY_BUILD) {
        case 'uk':
            return 'GTM-KH9323';
        case 'de':
            return 'GTM-PN3DHT';
        case 'pl':
            return 'GTM-WFSBG5';
        default:
            return 'GTM-KH9323';
    }
}

/**
 * Checks if the current browser is Chrome
 *
 * @export
 * @returns{bool} Result of the browser test
 */
export function checkIfChrome() {
    if (typeof window !== 'undefined') {
        return !!window.chrome;
    }
}

export function formatMoney(value) {
    if (getCountryBuild() === 'de') {
        return (
            <NumberFormat
                value={value}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={0}
                suffix=" €"
            />
        );
    }

    return (
        <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={0}
            prefix="£"
        />
    );
}

export const scrollToRef = (ref) =>
    window.scrollTo({
        top: ref.current.offsetTop - 50,
        behavior: 'smooth'
    });
