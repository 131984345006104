import React, { Component } from 'react';
import { generateSplitTestData, cleanUpOldCookies } from './IwSplitTestHelper';
import SplitTestContext from './IwSplitTestContext';
import styles from './IwSplitTestProvider.module.scss';

export default class IwSplitTestProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
    }

    getSplitTestContext() {
        const { data, config } = this.props;
        if (!config) return data;

        return generateSplitTestData(config);
    }

    componentDidMount() {
        const { config } = this.props;
        this.setState({ loaded: true });

        if (!config) return;
        cleanUpOldCookies(config);
    }

    render() {
        const { children } = this.props;
        const splitTestData = this.getSplitTestContext();

        const hasSplitTest = splitTestData
            ? Object.keys(splitTestData).length > 0
            : false;
        const showOverlay = hasSplitTest && !this.state.loaded;

        return (
            <SplitTestContext.Provider value={splitTestData}>
                {showOverlay ? <div className={styles.overlay} /> : null}
                {children}
            </SplitTestContext.Provider>
        );
    }
}
