// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-accountants-accountants-jsx": () => import("./../src/templates/Accountants/Accountants.jsx" /* webpackChunkName: "component---src-templates-accountants-accountants-jsx" */),
  "component---src-templates-cbils-cbils-jsx": () => import("./../src/templates/CBILS/CBILS.jsx" /* webpackChunkName: "component---src-templates-cbils-cbils-jsx" */),
  "component---src-templates-common-carousel-page-carousel-page-jsx": () => import("./../src/templates/Common/CarouselPage/CarouselPage.jsx" /* webpackChunkName: "component---src-templates-common-carousel-page-carousel-page-jsx" */),
  "component---src-templates-common-quick-jump-quick-jump-jsx": () => import("./../src/templates/Common/QuickJump/QuickJump.jsx" /* webpackChunkName: "component---src-templates-common-quick-jump-quick-jump-jsx" */),
  "component---src-templates-common-text-only-text-only-jsx": () => import("./../src/templates/Common/TextOnly/TextOnly.jsx" /* webpackChunkName: "component---src-templates-common-text-only-text-only-jsx" */),
  "component---src-templates-mca-mca-jsx": () => import("./../src/templates/MCA/MCA.jsx" /* webpackChunkName: "component---src-templates-mca-mca-jsx" */),
  "component---src-templates-uk-about-us-about-us-jsx": () => import("./../src/templates/uk/AboutUs/AboutUs.jsx" /* webpackChunkName: "component---src-templates-uk-about-us-about-us-jsx" */),
  "component---src-templates-uk-broker-broker-jsx": () => import("./../src/templates/uk/Broker/Broker.jsx" /* webpackChunkName: "component---src-templates-uk-broker-broker-jsx" */),
  "component---src-templates-uk-cbils-calculator-page-cbils-calculator-page-jsx": () => import("./../src/templates/uk/CBILSCalculatorPage/CBILSCalculatorPage.jsx" /* webpackChunkName: "component---src-templates-uk-cbils-calculator-page-cbils-calculator-page-jsx" */),
  "component---src-templates-uk-careers-careers-jsx": () => import("./../src/templates/uk/Careers/Careers.jsx" /* webpackChunkName: "component---src-templates-uk-careers-careers-jsx" */),
  "component---src-templates-uk-covid-hub-covid-hub-jsx": () => import("./../src/templates/uk/CovidHub/CovidHub.jsx" /* webpackChunkName: "component---src-templates-uk-covid-hub-covid-hub-jsx" */),
  "component---src-templates-uk-finance-explained-finance-explained-jsx": () => import("./../src/templates/uk/FinanceExplained/FinanceExplained.jsx" /* webpackChunkName: "component---src-templates-uk-finance-explained-finance-explained-jsx" */),
  "component---src-templates-uk-form-page-form-page-jsx": () => import("./../src/templates/uk/FormPage/FormPage.jsx" /* webpackChunkName: "component---src-templates-uk-form-page-form-page-jsx" */),
  "component---src-templates-uk-generic-landing-generic-landing-jsx": () => import("./../src/templates/uk/GenericLanding/GenericLanding.jsx" /* webpackChunkName: "component---src-templates-uk-generic-landing-generic-landing-jsx" */),
  "component---src-templates-uk-homepage-homepage-jsx": () => import("./../src/templates/uk/Homepage/Homepage.jsx" /* webpackChunkName: "component---src-templates-uk-homepage-homepage-jsx" */),
  "component---src-templates-uk-infroducer-signup-introducer-signup-jsx": () => import("./../src/templates/uk/InfroducerSignup/IntroducerSignup.jsx" /* webpackChunkName: "component---src-templates-uk-infroducer-signup-introducer-signup-jsx" */),
  "component---src-templates-uk-introducer-introducer-jsx": () => import("./../src/templates/uk/Introducer/Introducer.jsx" /* webpackChunkName: "component---src-templates-uk-introducer-introducer-jsx" */),
  "component---src-templates-uk-iwoca-pay-iwoca-pay-jsx": () => import("./../src/templates/uk/IwocaPay/IwocaPay.jsx" /* webpackChunkName: "component---src-templates-uk-iwoca-pay-iwoca-pay-jsx" */),
  "component---src-templates-uk-iwoca-pay-iwoca-pay-form-page-iwoca-pay-form-page-jsx": () => import("./../src/templates/uk/IwocaPay/IwocaPayFormPage/IwocaPayFormPage.jsx" /* webpackChunkName: "component---src-templates-uk-iwoca-pay-iwoca-pay-form-page-iwoca-pay-form-page-jsx" */),
  "component---src-templates-uk-iwoca-pay-iwoca-pay-sellers-page-v-2-iwoca-pay-sellers-page-v-2-jsx": () => import("./../src/templates/uk/IwocaPay/IwocaPaySellersPageV2/IwocaPaySellersPageV2.jsx" /* webpackChunkName: "component---src-templates-uk-iwoca-pay-iwoca-pay-sellers-page-v-2-iwoca-pay-sellers-page-v-2-jsx" */),
  "component---src-templates-uk-iwocapay-sellers-2-iwocapay-sellers-2-jsx": () => import("./../src/templates/uk/IwocapaySellers2/IwocapaySellers2.jsx" /* webpackChunkName: "component---src-templates-uk-iwocapay-sellers-2-iwocapay-sellers-2-jsx" */),
  "component---src-templates-contenthub-content-hub-home-page-js": () => import("./../src/templates/contenthub/contentHubHomePage.js" /* webpackChunkName: "component---src-templates-contenthub-content-hub-home-page-js" */),
  "component---src-templates-uk-article-page-article-page-js": () => import("./../src/templates/uk/ArticlePage/ArticlePage.js" /* webpackChunkName: "component---src-templates-uk-article-page-article-page-js" */),
  "component---src-templates-contenthub-category-page-js": () => import("./../src/templates/contenthub/categoryPage.js" /* webpackChunkName: "component---src-templates-contenthub-category-page-js" */),
  "component---src-templates-redirect-page-js": () => import("./../src/templates/redirectPage.js" /* webpackChunkName: "component---src-templates-redirect-page-js" */),
  "component---src-templates-uk-infroducer-signup-introducer-signup-invite-jsx": () => import("./../src/templates/uk/InfroducerSignup/IntroducerSignupInvite.jsx" /* webpackChunkName: "component---src-templates-uk-infroducer-signup-introducer-signup-invite-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

